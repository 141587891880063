function InfoSection() {
  return (
    <>
      <section
        className="uk-section uk-section-muted
       uk-section-large"
      >
        <div className="uk-container uk-margin-small-bottom">
          <h2 className="uk-h1">
            В юбилейный год Фонд Игоря Бутмана планирует проведение ряда
            благотворительных мероприятий, собранные средства с которых пойдут
            на следующие цели:
          </h2>
          <ul className="uk-text-large">
            <li> организация гастрольного тура в 25 городов России;</li>
            <li> обновление инструментов Московского джазового оркестра;</li>
            <li>
              поддержка образовательных мероприятий в рамках празднования
              25-летия Оркестра;
            </li>
            <li>издание нового альбома Московского джазового оркестра;</li>
            <li>
              осуществление программ для повышения мастерства и поддержки
              студентов Академии джаза;
            </li>
          </ul>
          <a
            href="#about-goals"
            className="button button_accent uk-button uk-button-primary uk-button-large uk-border-rounded"
            data-uk-scroll
          >
            Подробнее
          </a>
        </div>
      </section>
    </>
  );
}
export default InfoSection;
