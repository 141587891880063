// import { useState } from 'react';
// import Navbar from '../components/navbar/Navbar';
import BannerFundSection from '../components/banner-fund-section/BannerFundSection';
import BannerSection from '../components/banner-section/BannerSection';
import InfoSection from '../components/info-section/InfoSection';
import FundSection from '../components/fund-section/FundSection';
import SiteFooter from '../components/site-footer/SiteFooter';

export const HomePage = () => {
  return (
    <>
      {/* <Navbar /> */}
      <BannerSection />
      <BannerFundSection />
      <InfoSection />
      <FundSection />
      <SiteFooter />
    </>
  );
};
