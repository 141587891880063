import banner from '../../assets/images/mjo25/mk.jpg';

import styles from './BannerSection.module.css';

function BannerSection() {
  return (
    <section className="uk-cover-container" data-uk-height-viewport>
      <img loading="async" src={banner} alt="" data-uk-cover />
      <div
        className="uk-position-cover"
        style={{ backgroundColor: 'rgba(0, 0, 0, 0.35)' }}
      ></div>
      <div
        className="uk-position-absolute uk-width-1-1 uk-container"
        style={{ bottom: '5vh', zIndex: 1 }}
      >
        <div className="el-overlay uk-panel uk-light uk-margin-remove-first-child">
          <h1
            className={`${styles.title} uk-heading-medium uk-margin-top uk-margin-remove-bottom`}
            style={{ display: 'none' }}
          >
            Юбилей Анны Бутман
          </h1>
          <div
            className={`${styles.description} uk-margin-small-top uk-margin-bottom uk-width-xlarge`}
            style={{ color: '#fff' }}
          >
            Фонд поддержки и развития музыкального искусства Игоря Бутмана
            открывает сбор денежных средств, посвященный 25-летнему юбилею
            Московского джазового оркестра. Сбор денежных средств продлится весь
            юбилейный год.
          </div>

          <a
            href="#fund"
            className={`${styles.arrow} uk-position-absolute uk-position-bottom-right uk-margin-bottom`}
            data-uk-scroll
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#fff"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-arrow-down-circle"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="8 12 12 16 16 12"></polyline>
              <line x1="12" y1="8" x2="12" y2="16"></line>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
}

export default BannerSection;
