import fund1 from '../../assets/images/fund-1.jpeg';
import fund2 from '../../assets/images/fund-2.jpg';
import fund3 from '../../assets/images/fund-3.jpeg';
import fund4 from '../../assets/images/fund-4.jpeg';

import styles from './FundSection.module.css';
import qrcode from '../../assets/images/qrcode.svg';
import { getWord } from '../../utils/helpers';

function FundSection() {
  let total = '100 000 ₽';
  let people = 20;
  let word = getWord(people);

  return (
    <>
      <section
        className="uk-section uk-section-primary uk-section-large"
        style={{ background: 'var(--global-color-accent-dark)' }}
      >
        <div className="uk-container uk-margin-small-bottom">
          <div className="uk-child-width-1-3@m uk-grid" data-uk-grid>
            <div className="uk-text-center uk-text-left@m">
              <h2 className="uk-h2 uk-heading-medium">Статус:</h2>
              <p className="uk-h2 uk-heading-medium uk-margin-remove-top">
                {total}
              </p>
              <p
                className="uk-text-medium uk-text-uppercase uk-text-secondary"
                style={{ fontWeight: 'bold' }}
              >
                Фонд поддержали: {people} {word}
              </p>
            </div>

            <div className="uk-text-center">
              <p className="uk-text-secondary uk-text-small">
                Оплатите по QR-коду
              </p>
              <img
                src={qrcode}
                alt="Для того, чтобы сделать пожертвование, наведите камеру смартфона на QR-код"
              />
            </div>

            <div className="uk-text-center">
              <p className="uk-text-secondary uk-text-small">
                Или кликните по кнопке
              </p>
              <a
                href="https://securecardpayment.ru/sc/nHGGMQoxiFapfPmY"
                className="button button_main uk-button uk-button-secondary uk-button-large uk-border-rounded"
                style={{ color: '#1d1d1d' }}
              >
                Поддержать фонд
              </a>
            </div>
          </div>
        </div>
      </section>
      <section
        id="about"
        className="section section_fund uk-section uk-section-medium uk-section-muted"
      >
        <div className="uk-container">
          <h2 className={`${styles.title} uk-h2 uk-heading-medium`}>
            Фонд поддержки и развития музыкального искусства
          </h2>
          <div
            className="uk-grid-medium uk-child-width-1-2@m uk-margin-top"
            data-uk-grid
          >
            <div>
              <p className="uk-width-xlarge uk-text-medium uk-text-main">
                Некоммерческая организация, основанная Народным артистом России
                Игорем Бутманом для поддержки и развития джазовой сцены и
                образования в России.
              </p>
              <p className="uk-width-xlarge uk-text-medium uk-text-main">
                С 2017 года Фонд проводит единственный в России профессиональный
                международный джазовый форум-фест Jazz Across Borders в
                Санкт-Петербурге. Фонд регулярно поддерживает единственную в
                России Академию Джаза, международные конкурсы молодых
                исполнителей «Мир Джаза» и «Детский Триумф Джаза», а также
                инициирует и финансирует стажировки студентов Академии и иных
                юных талантливых музыкантов в России и за рубежом.
              </p>
            </div>
            <div>
              <p className="uk-width-xlarge uk-text-medium uk-text-main">
                За годы своего существования Фонд провёл ряд крупнейших джазовых
                мероприятий в истории нашей страны, включая Международный день
                джаза в Санкт-Петербурге (2018 год, совместно с ЮНЕСКО и Herbie
                Hancock Institute of Jazz).
              </p>
              <p className="uk-width-xlarge uk-text-medium uk-text-main">
                В 2020 году, в период пандемии, Фонд оказал поддержку музыкантам
                и многодетным семьям музыкантов, оказавшимся в трудной
                финансовой ситуации в связи с пандемией коронавируса.
              </p>
              <p className="uk-width-xlarge uk-text-medium uk-text-main">
                C 2022 года Фонд организовывает и успешно проводит самый крупный
                джазовый фестиваль России Moscow Jazz Festival.
              </p>
            </div>
          </div>
        </div>
        <div className="uk-container uk-margin-large-top">
          <div
            className="uk-grid-match uk-grid-medium uk-child-width-1-2@s"
            data-uk-grid
          >
            <div>
              <img
                className="uk-border-rounded"
                loading="async"
                src={fund1}
                alt=""
              />
            </div>
            <div>
              <img
                className="uk-border-rounded"
                loading="async"
                src={fund2}
                alt=""
              />
            </div>
            <div>
              <img
                className="uk-border-rounded"
                loading="async"
                src={fund3}
                alt=""
              />
            </div>
            <div>
              <img
                className="uk-border-rounded"
                loading="async"
                src={fund4}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="uk-container uk-margin-medium-top">
          <h2 id="about-goals">
            Также средства будут распределены на достижение уставных целей
            Фонда:
          </h2>
          <ul>
            <li>
              организация и проведение музыкальных мероприятий, в том числе
              фестивалей международного уровня
            </li>
            <li>
              содействие в создание материально-технической базы в целях
              поддержки музыкального сообщества и развитие мирового и
              отечественного джазового искусства
            </li>
            <li>
              участие в организации и проведении музыкальных конкурсов,
              фестивалей, в интересах знакомства профессионалов и широкой
              публики на территории Российской Федерации и за рубежом с
              достижениями отечественной и мировой сцены
            </li>
            <li>
              содействие и организация участия российских исполнителей в
              конкурсах, фестивалях, в том числе, международных
            </li>
            <li>
              поддержка экспериментальных музыкальных проектов, содействие их
              продвижению в РФ и за рубежом
            </li>
            <li>
              содействие и создание совместных российских и международных
              музыкальных проектов
            </li>
            <li>
              организация и проведение гастролей российских и зарубежных
              музыкальных коллективов, их участие в международных акциях в
              России и за рубежом
            </li>
            <li>
              содействие и осуществление программ, способствующих повышению
              профессионального уровня и мастерства молодых творческих кадров и
              распространению достижений русского джаза в России и за рубежом
            </li>
            <li>
              содействие и организация выпуска печатных изданий, брошюр,
              журналов, аудио и видеопродукции направленных на распространение
              трудов, отражающих современные музыкальные явления, работ по
              теории и истории музыки и, в частности, джаза.
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}

export default FundSection;
